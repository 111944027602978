@tailwind base;
@tailwind components;
@tailwind utilities;

tr.htmx-swapping td {
  opacity: 0;
  transition: opacity 1s ease-out;
}

.clearablefileinput {
  @apply file-input w-full;
}
